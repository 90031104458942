






























import ModalDeleteAccountingFirm from "@/components/modal/accountingFirm/ModalDeleteAccountingFirm.vue";
import { AccountingFirmAcction, BannerActionMenu, AccountingFirmStatus, LoadingStatusType } from "@/enums/enums";
import AuthService from "@/service/authService";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const auth = new AuthService();
const StoreAccountingFrim = namespace("AccountingFrim");

@Component({
  components: {
    ModalDeleteAccountingFirm,
  },
})
export default class AccountingFirmActionList extends Vue {
  @Prop({ required: true }) readonly item!: any;
  private isShowDeleteAccountingFirmModal = false;
  private merchantDropdownModelSelect: any = null;
  private items = [{ title: "Edit" }, { title: "Delete" }];

  private accountingFirmAction: any = [];
  private showList = false;

  @StoreAccountingFrim.Getter
  private getAccountingFirmsTableLoadingStatus!: LoadingStatusType | null;

  constructor() {
    super();
    this.initializeMenuList();
  }

  @Watch("getAccountingFirmsTableLoadingStatus", { immediate: true })
  getAccountingFirmsTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.initializeMenuList();
    }
  }

  public async initializeMenuList(): Promise<void> {
    const accountingFirmPermissions = await this.getAccountingFirmPermissions();
    this.accountingFirmAction = [
      {
        text: "Edit",
        actionValue: AccountingFirmAcction.Edit,
        permission: accountingFirmPermissions.edit,
      },
      {
        text: "Delete",
        actionValue: AccountingFirmAcction.Delete,
        permission:
          accountingFirmPermissions.delete && this.item.accountingFirmStatus !== AccountingFirmStatus.Inactive,
      },
    ];
  }
  public async getAccountingFirmPermissions() {
    const accessPermission = await auth.getPermissions();
    const permission = accessPermission["accountingFirm"];
    return permission;
  }
  handleItemClick(actionItem: any) {
    const { actionValue } = actionItem;
    switch (actionValue) {
      case AccountingFirmAcction.Edit:
        this.EditAccountingFirm();
        break;
      case AccountingFirmAcction.Delete:
        this.DeleteAccountingFirm();
        break;
    }
  }
  public EditAccountingFirm() {
    const routeData = this.$router.resolve({
      name: "accountingFirmEdit",
      query: { id: this.item.id },
    });
    window.open(routeData.href, "_blank");
  }
  public DeleteAccountingFirm() {
    this.isShowDeleteAccountingFirmModal = true;
  }
  public ShowActionList(newValue: boolean) {
    this.showList = newValue;
  }
}
