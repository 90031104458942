

























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { LoadingStatusType, AccountingFirmStatus, SortType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import AccountingFirmActionList from "@/views/accountingFirm/AccountingFirmActionList.vue";

import AuthService from "@/service/authService";
const StoreAccountingFrim = namespace("AccountingFrim");
const auth = new AuthService();

@Component({ components: { AccountingFirmActionList } })
export default class AccountingFirmTable extends Vue {
  private textSearch = "";
  private codeSearch = "";
  sortBy: any = null;
  sortDesc: any = null;
  private table: any = {
    header: [
      { text: "", value: "index", sortable: false },
      { text: "ID", value: "id", sortable: false },
      { text: "Code", value: "internalCode", sortable: true, sortType: SortType.Order },
      { text: "Name", value: "name", sortable: true, sortType: SortType.Order },
      { text: "Name En", value: "nameEn", sortable: true, sortType: SortType.Order },
      { text: "Phone Number", value: "phoneNumber", sortable: false },
      { text: "Ref Code", value: "refCode", sortable: false },
      { text: "Status", value: "isdeleted", sortable: true, sortType: SortType.Select },
      { text: "Action List", value: "actionList", sortable: false },
    ],
    detail: [],
  };

  private enabled = null;
  private dateCurrent = new Date();
  private thirdPreviosDate = this.dateCurrent.setDate(this.dateCurrent.getDate() - 3);
  private datefrom = new Date(this.thirdPreviosDate - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  private menufrom = false;
  private modalfrom = false;
  private menu2from = false;
  private dateto = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  private menuto = false;
  private modalto = false;
  private menu2to = false;
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;
  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];
  private createPermission = false;
  private detailPermission = false;
  selectedItem = "";
  selectedSortBy = "";
  SortType = SortType;
  filterStatus = {
    active: false,
    inactive: false,
  };

  @StoreAccountingFrim.Action
  private DoGetAccountingFirmTable!: (input: any) => void;

  @StoreAccountingFrim.Getter
  private getAccountingFirmTable!: Array<any> | null;

  @StoreAccountingFrim.Getter
  private getAccountingFirmsTableLoadingStatus!: LoadingStatusType | null;
  @StoreAccountingFrim.Getter
  private getCountItemTable!: number;

  constructor() {
    super();
    this.getAccountingFirmPermission();
  }

  created() {
    this.GetAccountingFirmTable(this.pageNo, this.pageSize);
  }

  @Watch("pageNo")
  pageNoChanged(newVal: number) {
    this.GetAccountingFirmTable(this.pageNo, this.pageSize);
  }

  @Watch("pageSize")
  pageSizeChanged(newVal: number) {
    this.GetAccountingFirmTable(1, this.pageSize);
  }
  @Watch("filterStatus.active")
  filterStatusActiveChanged(newVal: any) {
    this.GetAccountingFirmTable(this.pageNo, this.pageSize);
  }
  @Watch("filterStatus.inactive")
  filterStatusInactiveChanged(newVal: any) {
    this.GetAccountingFirmTable(this.pageNo, this.pageSize);
  }
  @Watch("getAccountingFirmsTableLoadingStatus", { immediate: true })
  getAccountingFirmsTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.table.detail = [];
      if (this.getAccountingFirmTable && this.getAccountingFirmTable.length > 0) {
        this.getAccountingFirmTable.forEach((item: any) => {
          this.table.detail.push(NewObject(item));
        });
      }
      this.table.detail.sort((a: any, b: any) => a.order - b.order);
      this.countItem = this.getCountItemTable;

      if (this.countItem === 0) {
        this.totalPage = 1;
      } else {
        this.totalPage = Math.ceil(this.getCountItemTable / this.pageSize);
      }
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }
  public SearchAccountingFirmTable() {
    this.GetAccountingFirmTable(this.pageNo, this.pageSize);
  }

  public GetAccountingFirmTable(pageNo = this.pageNo, pageSize = this.pageSize) {
    let accountingFirmStatus = AccountingFirmStatus.None;
    if (this.filterStatus.active && !this.filterStatus.inactive) {
      accountingFirmStatus = AccountingFirmStatus.Active;
    } else if (!this.filterStatus.active && this.filterStatus.inactive) {
      accountingFirmStatus = AccountingFirmStatus.Inactive;
    } else {
      accountingFirmStatus = AccountingFirmStatus.None;
    }
    const input = {
      pageSize: pageSize,
      pageNo: pageNo,
      name: this.textSearch,
      code: this.codeSearch,
      sortItem: this.selectedItem,
      sortBy: this.selectedSortBy,
      accountingFirmStatus,
    };
    this.DoGetAccountingFirmTable(input);
  }

  public SortTable(column: string, order: string) {
    this.selectedItem = column;
    this.selectedSortBy = order;
    this.GetAccountingFirmTable(this.pageNo, this.pageSize);
  }
  public ClearSortTable() {
    this.selectedSortBy = "desc";
    this.selectedItem = "";
    this.GetAccountingFirmTable(this.pageNo, this.pageSize);
  }

  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }

  public Create() {
    this.$router.push({
      name: "accountingFirmCreate",
    });
  }
  public GetTextStatus(status: number) {
    let statusText = "";
    switch (status) {
      case AccountingFirmStatus.None:
        statusText = "-";
        break;
      case AccountingFirmStatus.Active:
        statusText = "Active";
        break;
      case AccountingFirmStatus.Inactive:
        statusText = "Inactve";
        break;
    }
    return statusText;
  }
  public AccountingFirmDetail(id: any) {
    if (this.detailPermission) {
      const routeData = this.$router.resolve({
        name: "accountingFirmDetail",
        query: { id: id },
      });
      window.open(routeData.href, "_blank");
    } else {
      alert("คุณไม่มีสิทธิเข้าหน้า Detail");
    }
  }

  public async getAccountingFirmPermission() {
    const accessPermission = await auth.getPermissions();
    this.createPermission = accessPermission["accountingFirm"]["create"];
    this.detailPermission = accessPermission["accountingFirm"]["detail"];
  }
}
